import React from 'react';
import newtire0 from './images/edy7.jpg'
import newtire11 from './images/edy8.jpg'
import newtire12 from './images/edutire.jpg'

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <h1>About Us</h1>
      <div className="about-us-content">
        <div className="about-us-section">
          <h2>Our Mission</h2>
          <p>
            At Edy Tire Service, our mission is to provide exceptional tire services and solutions that ensure the safety and performance of your vehicle. We are committed to delivering high-quality products and outstanding customer service with a focus on reliability and integrity.
          </p>
        </div>
        <div className="about-us-section">
          <h2>Our Vision</h2>
          <p>
            Our vision is to be the leading tire service provider in the region, known for our innovation, customer satisfaction, and environmental responsibility. We strive to continually improve our services and expand our reach to meet the evolving needs of our customers.
          </p>
        </div>
        <div className="about-us-section">
          <h2>Our Team</h2>
          <p>
            Our team consists of highly skilled and experienced professionals who are passionate about delivering top-notch tire services. Each member of our team is dedicated to ensuring that you receive the best possible service and advice for your vehicle.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
